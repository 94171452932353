<template>
  <section>
    <div class="accordion">
      <div :ref="`block0${i+1}`" :id="`block0${i+1}`" class="accordion__container" v-for="(item, i) in floor_list" :key="`panel${i}`">
        <button @click="toggleAccordion(i)" :class="['accordion__tab', {'accordion__tab--active': activeAccordion == i }]" :aria-selected="activeAccordion == `panel${i}`" role="tab">{{ item.button }}</button>
        <div :class="['accordion__block', {'accordion__block--active': activeAccordion == i }]">
          <div class="accordion__block-inner">
            <div class="accordion__columns">
              <div class="accordion__text">
                <h3 class="accordion__header" v-html="item.title"></h3>
                <p>{{ item.text }}</p>
                <ul class="accordion__list">
                  <li class="accordion__list-item" v-for="(area, k) in item.areas" :key="`area${k}`">
                    <strong v-if="area.area_label" :class="`accordion__color--${area.color}`">{{ area.area_label}}</strong>
                    {{ area.area_text}}
                  </li>
                </ul>
              </div>
              <div class="accordion__image-bg">
                <img v-if="item.image" v-lazy="require(`../assets/images${item.image}`)" width="680" height="570" alt="" class="accordion__image">
                <span class="accordion__image-loading"></span>
                <span class="accordion__image-scale">{{ $t('global.scale_label') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    floor_list: Array,
    active_accordion: Number,
  },
  data () {
    return {
      activeAccordion: this.$props.active_accordion,
    }
  },
  mounted () {
    if (window.innerWidth < 768) {
      this.activeAccordion = 0
    }
  },
  methods: {
    toggleAccordion (index) {
      this.activeAccordion = index

      if (window.innerWidth < 768) {
        let articleChildren = Array.from(document.getElementsByClassName('article')[0].children)
        let accordionSection = document.querySelector('.accordion').parentElement
        let height = 0
        let accordionIndex = 0
        let remove = 0

        articleChildren.forEach(function(item, index){
          if (item == accordionSection) {
            accordionIndex = index
          }
        })

        remove = articleChildren.length - accordionIndex
        articleChildren.splice(accordionIndex, remove)

        articleChildren.forEach(function(item){
            height += item.clientHeight + parseInt(window.getComputedStyle(item).getPropertyValue('margin-top')) + parseInt(window.getComputedStyle(item).getPropertyValue('margin-bottom'))
        })

        setTimeout(function() {
          let scrollTop = height + (index * 103)
          window.scrollTo({ top: scrollTop, behavior: 'smooth' });
        }, 300);
      }
    }
  },
}
</script>
