<template>
<svg :class="logoClass" width="113" height="113" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M56.028 111.056c30.391 0 55.028-24.637 55.028-55.028S86.419 1 56.028 1 1 25.637 1 56.028s24.637 55.028 55.028 55.028Z" stroke="#245B41" stroke-width="2"/><path d="M42.313 44.987a7.169 7.169 0 1 0-.001-14.337 7.169 7.169 0 0 0 0 14.337M70.986 44.987a7.169 7.169 0 1 0-.001-14.337 7.169 7.169 0 0 0 0 14.337" fill="#245B41"/><path d="M63.818 18.41a7.168 7.168 0 0 0-14.336 0c0 3.63 2.7 6.622 6.2 7.096v62.127c-3.5.473-6.2 3.465-6.2 7.095a7.169 7.169 0 1 0 14.336 0c0-3.622-2.69-6.608-6.18-7.091V25.502c3.49-.483 6.18-3.47 6.18-7.092M39.447 57.77c-1.303-.553-2.705-.83-4.203-.83-2.862 0-5.189 1.003-6.974 3.008-1.506 1.685-2.26 3.667-2.26 5.946 0 1.662.407 3.21 1.223 4.646.816 1.436 1.924 2.542 3.325 3.318 1.4.776 2.993 1.165 4.78 1.165 1.67 0 3.126-.356 4.365-1.065 1.241-.71 2.252-1.74 3.032-3.09.78-1.35 1.17-2.778 1.17-4.284l-.023-.726h-8.66v3.079h4.986c-.477 1.014-1.105 1.759-1.881 2.235-.777.476-1.733.714-2.864.714-1.742 0-3.208-.577-4.395-1.732-1.187-1.155-1.78-2.563-1.78-4.225 0-1.608.572-2.973 1.715-4.097 1.145-1.123 2.563-1.685 4.258-1.685 1.945 0 3.647.772 5.108 2.317l2.307-2.282c-1.053-1.14-2.13-1.943-3.23-2.411m42.888 0c-1.305-.554-2.705-.831-4.203-.831-2.864 0-5.189 1.003-6.975 3.008-1.507 1.685-2.26 3.667-2.26 5.946 0 1.662.409 3.21 1.224 4.646.816 1.436 1.923 2.542 3.323 3.318 1.401.776 2.996 1.165 4.782 1.165 1.67 0 3.125-.356 4.366-1.065 1.24-.71 2.25-1.74 3.03-3.09.782-1.35 1.172-2.778 1.172-4.284l-.025-.726h-8.66v3.079h4.986c-.476 1.014-1.103 1.759-1.88 2.235-.778.476-1.733.714-2.865.714-1.743 0-3.207-.577-4.395-1.732-1.186-1.155-1.78-2.563-1.78-4.225 0-1.608.572-2.973 1.716-4.097 1.144-1.123 2.564-1.685 4.258-1.685 1.945 0 3.648.772 5.108 2.317l2.308-2.282c-1.053-1.14-2.13-1.943-3.23-2.411" fill="#245B41"/></g></svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    logoClass: String,
  },
}
</script>
<style lang="scss">
</style>
