<template>
  <section class="slider__container">
    <ul ref="sliderButtons" class="slider__paging">
      <li ref="prev" @click="slideImage(-1)" aria-controls="carouselItems" aria-label="previous">zurück</li>
      <li class="slider__paging-indicator"><strong>{{ this.activeImage }}</strong> / {{ view_list.length }} </li>
      <li ref="next" @click="slideImage(1)" aria-controls="carouselItems" aria-label="next">nächste</li>
    </ul>

    <swiper
      ref="swiper"
      :slides-per-view="1"
      :loop="true"
      :pagination="false"
      :navigation="false"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(item, i) in view_list"
        :key="`view${i+1}`"
        :ref="`slide${i+1}`"
        :data-number="`${i+1}`"
        :class="{'slider__item--active': activeImage == i+1}"
      >
        <img :src="require(`../assets/images/${item.image}`)">
        <h4 class="slider__item-title">{{ item.title }}</h4>
        <p class="slider__item-text">{{ item.text }}</p>
      </swiper-slide>
    </swiper>

  </section>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])

export default {
  name: 'Slider',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    view_list: Array,
  },
  data () {
    return {
      activeImage: 1,
    }
  },
  methods: {
    onSlideChange (swiper) {
      if (swiper.activeIndex > this.view_list.length) {
        this.activeImage = 1;
      }
      else if (swiper.activeIndex === 0) {
        this.activeImage = this.view_list.length;
      }
      else {
        this.activeImage = swiper.activeIndex;
      }
    },
    slideImage(direction){
      if (direction === -1) {
        this.$refs.swiper.$el.swiper.slidePrev()
      }
      else if (direction === 1) {
        this.$refs.swiper.$el.swiper.slideNext()
      }
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
